<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.sex"
            placeholder="请选择性别"
            style="width: 120px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="男">男</a-select-option>
            <a-select-option value="女">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 300px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['申请开始时间', '申请结束时间']"
            style="width: 300px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.company_audit_staus"
            placeholder="公司审核状态"
            style="width: 150px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">待审核</a-select-option>
            <a-select-option value="2">审核通过</a-select-option>
            <a-select-option value="3">审核拒绝</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.team_audit_staus"
            placeholder="劳务队审核状态"
            style="width: 150px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">待审核</a-select-option>
            <a-select-option value="2">审核通过</a-select-option>
            <a-select-option value="3">审核拒绝</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
<!--        <a-form-item>-->
<!--          <a-button-->
<!--            :icon="getExicon.icon"-->
<!--            :disabled="getExicon.ed"-->
<!--            @click="$exportFun('/export/inProject', page)"-->
<!--            >导出</a-button-->
<!--          >-->
<!--        </a-form-item>-->
        <a-form-item>
          <a-button
            type="primary"
            :disabled="selectedRowKeys.length == 0"
            @click="openexamineData(selectedRowKeys)"
            icon="check-circle"
            >批量审核</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
        :row-selection="rowSelection"
      >
        <template slot="state" slot-scope="text, record">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="sex" slot-scope="text, record">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="orange"> 审核中 </a-tag>
          <a-tag v-if="text == '2'" color="green"> 已通过 </a-tag>
          <a-tag v-if="text == '3'" color="red"> 已拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text, record">
          <view-file :data="text"></view-file>
        </template>
        <template slot="identity" slot-scope="text, record">
          <div>
            <a-tag color="#f50" v-if="text === 'BLACK'">黑名单</a-tag>
            <a-tag color="orange" v-if="text === 'WARN'">警告</a-tag>
            <a-tag color="#87d068" v-if="text === 'NORMAL'">正常</a-tag>
          </div>
          <div>
            <span style="font-size: 12px;color: #f50;line-height: 22px;text-align: left">{{record.identity_content}}</span>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            v-if="
              platformCode &&
              text.team_audit_staus != 1 &&
              text.company_audit_staus == 1
            "
            size="small"
            icon="check-circle"
            type="primary"
            @click="() => openexamineData(record['id'])"
            >审核</a-button
          >
          <a-tag
            v-if="platformCode && text.team_audit_staus == 1"
            color="orange"
            >劳务队待审核</a-tag
          >
          <a-button
            v-if="!platformCode && text.team_audit_staus == 1"
            size="small"
            icon="check-circle"
            type="primary"
            @click="() => openexamineData(record['id'])"
            >审核</a-button
          >
          <a-tag
            v-if="text.team_audit_staus != 1 && text.company_audit_staus != 1"
            color="cyan"
            >审核完成</a-tag
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      title="审核"
      :visible="examineVisible"
      @ok="UpdateexamineData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="UpdateexamineForm"
        :rules="examineRules"
        :model="examineData"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="审核" prop="audit_state">
          <a-select placeholder="请审核" v-model="examineData.audit_state">
            <a-select-option value="2">通过</a-select-option>
            <a-select-option value="3">拒绝</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="拒绝理由"
          prop="refuse_reason"
          v-if="examineData.audit_state == 3"
        >
          <a-input
            type="textarea"
            row="2"
            v-model="examineData.refuse_reason"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      selectedRowKeys: [],
      tableHeigt: 500,
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      viewImgData: "",
      examineData: {
        ids: "",
        audit_state: undefined,
        refuse_reason: "",
      },

      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "序号",
          width: 70,
          align: "center",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        // {
        //   title: "性别",
        //   dataIndex: "sex",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "年龄",
        //   dataIndex: "age",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "民族",
        //   dataIndex: "national",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "籍贯",
        //   dataIndex: "census_register_seat",
        //   width: 270,
        // },

        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "项目名称",
          dataIndex: "pname",
          width: 200
        },
        {
          title: "劳务队",
          dataIndex: "tname",
          width: 90,
        },
        {
          title: "黑名单/警告状态",
          dataIndex: "identity",
          align: "center",
          width: 180,
          scopedSlots: { customRender: "identity" },
        },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
        },
        {
          title: "特殊工种图片",
          dataIndex: "special_work_img",
          width: 120,
          scopedSlots: { customRender: "idno_positive_img_url" },
        },
        // {
        //   title: "申请时间",
        //   dataIndex: "create_time",
        //   width: 180,
        // },
        // {
        //   title: "申请理由",
        //   dataIndex: "apply_reason",
        //   width: 100,
        // },
        // {
        //   title: "劳务队审核人",
        //   dataIndex: "team_audit_name",
        //   width: 140,
        // },
        {
          title: "劳务队审核状态",
          dataIndex: "team_audit_staus",
          scopedSlots: { customRender: "status" },
          width: 140,
          align: "center",
        },
        // {
        //   title: "劳务队审核时间",
        //   dataIndex: "team_audit_time",
        //   width: 200,
        // },
        // {
        //   title: "劳务公司审核人",
        //   dataIndex: "company_audit_name",
        //   width: 140,
        // },
        {
          title: "劳务公司审核状态",
          dataIndex: "company_audit_staus",
          scopedSlots: { customRender: "status" },
          width: 150,
          align: "center",
        },
        // {
        //   title: "劳务公司审核时间",
        //   dataIndex: "company_audit_time",
        //   width: 200,
        // },
        // {
        //   title: "拒绝原因",
        //   dataIndex: "refuse_reason",
        //   width: 140,
        // },
        {
          title: "身份证号",
          dataIndex: "idno",
          width: 150
        },
        // {
        //   title: "民族",
        //   dataIndex: "national",
        //   width: 70,
        //   align: "center",
        // },
        {
          title: "申请时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 120,
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        sex: undefined,
        project_id: undefined,
        start_time: "",
        end_time: "",
        work_id: undefined,
        company_audit_staus: undefined,
        team_audit_staus: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
      platformCode: window.localStorage.getItem("platformCode") != "labor_team",
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          console.log(this.selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.platformCode
              ? !(
                  record.team_audit_staus != 1 &&
                  record.company_audit_staus == 1
                )
              : !(!this.platformCode && record.team_audit_staus == 1),

            name: "审核完成",
          },
        }),
      };
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },

    cancel() {
      this.examineVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          this.resetPwd(id);
        },
        onCancel() {},
      });
    },

    async resetPwd(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    hideModal() {
      this.imgvisible = false;
    },
    // 审核弹框
    openexamineData(id) {
      if (typeof id == "string" || typeof id == "number") {
        this.examineData.ids = id;
      } else {
        this.examineData.ids = id.join(",");
      }
      this.examineVisible = true;
    },

    /**
     * 审核
     *
     */
    UpdateexamineData() {
      this.$refs.UpdateexamineForm.validate(async (valid) => {
        if (valid) {
          let res = await Api.Updateexamine({ ...this.examineData });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.examineVisible = false;
            this.getList();
            this.selectedRowKeys = [];
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
  },
};
</script>

<style scoped>
</style>

