<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 200px"
            v-model="page.sex"
            placeholder="请选择性别"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="男">男</a-select-option>
            <a-select-option value="女">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 350px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 200px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['申请开始时间', '申请结束时间']"
            style="width: 400px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/lwry', page, 'userLog')"
            >导出</a-button
          >
        </a-form-item>
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
      >
        <template slot="state" slot-scope="text, record">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="is_reserve_personnel" slot-scope="text">
          <a-tag color="orange" v-if="text == '1'">否</a-tag>
          <a-tag color="green" v-if="text == '2'">是</a-tag>
        </template>
        <template slot="sex" slot-scope="text, record">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="orange"> 审核中 </a-tag>
          <a-tag v-if="text == '2'" color="green"> 已通过 </a-tag>
          <a-tag v-if="text == '3'" color="red"> 已拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text, record">
          <view-file :data="text"></view-file>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            @click="reserve_personnel(record)"
            type="primary"
            v-if="record.is_reserve_personnel == 1"
            size="small"
            icon="heart"
            >加入人才储备库</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      title="审核"
      :visible="examineVisible"
      @ok="UpdateexamineData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="UpdateexamineForm"
        :rules="examineRules"
        :model="examineData"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="审核" prop="audit_state">
          <a-select placeholder="请审核" v-model="examineData.audit_state">
            <a-select-option value="2">通过</a-select-option>
            <a-select-option value="3">拒绝</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="拒绝理由"
          prop="refuse_reason"
          v-if="examineData.audit_state == 3"
        >
          <a-input
            type="textarea"
            row="2"
            v-model="examineData.refuse_reason"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      tableHeigt: 0,
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      viewImgData: "",
      examineData: {
        id: "",
        audit_state: undefined,
        refuse_reason: "",
      },
      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "序号",
          width: 70,
          align: "center",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "姓名",
          dataIndex: "uname",
          width: 100,
        },
        {
          title: "是否人才储备",
          dataIndex: "is_reserve_personnel",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "is_reserve_personnel" },
        },
        // {
        //   title: "年龄",
        //   dataIndex: "age",
        //   width: 70,
        //   align: "center",
        // },

        // {
        //   title: "籍贯",
        //   dataIndex: "census_register_seat",
        //   width: 270,
        // },

        // {
        //   title: "退场时间",
        //   dataIndex: "out_time",
        //   width: 180,
        // },
        {
          title: "劳动合同编号",
          dataIndex: "user_contract_code",
          width: 200
        },
        {
          title: "项目名称",
          dataIndex: "pname",
          width: 200
        },

        {
          title: "工种名称",
          dataIndex: "wname",
          align: "center",
          width: 100,
        },
        {
          title: "考试分数",
          dataIndex: "total_score",
          align: "center",
          width: 100,
        },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证号",
          dataIndex: "idno",
          width: 150
        },
        {
          title: "民族",
          dataIndex: "national",
          width: 70,
          align: "center",
        },
        {
          title: "进场时间",
          dataIndex: "in_time",
          width: 180,
        },
        {
          title: "操作",
          width: 170,
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        sex: undefined,
        project_id: undefined,
        work_id: undefined,
        start_time: "",
        end_time: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          this.resetPwd(id);
        },
        onCancel() {},
      });
    },

    async resetPwd(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    hideModal() {
      this.imgvisible = false;
    },
    // 审核弹框
    async openexamineData(id) {
      this.examineData.id = id;
      this.examineVisible = true;
    },
    /**
     * 审核
     *
     */
    UpdateexamineData() {
      this.$refs.UpdateexamineForm.validate(async (valid) => {
        if (valid) {
          let res = await Api.Updateexamine({ ...this.examineData });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.examineVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    // 关闭窗口
    cancel() {
      this.examineVisible = false;
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
    reserve_personnel(row) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: `确定要将【${row.uname}】加入到人才储备库吗?`,
        onOk() {
          that.GoReserve_personnel(row.id);
        },
        onCancel() {},
      });
    },
    async GoReserve_personnel(id) {
      let res = await Api.Personnel({ids:id});
      if (res && res["code"] == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style scoped>
</style>

